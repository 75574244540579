<template>
  <template v-if="info.status === '0'">
    <van-cell :title="info.result.expName" value="联系他" :label="info.result.number" is-link @click="onTel(info.result.courierPhone)" />
    <div style="margin:15px;">
      <van-steps direction="vertical" :active="0">
        <van-step v-for="item in info.result.list" :key="item">
          <h5>{{ item.status }}</h5>
          <h5 style="margin:7px 0 0 0;">{{ item.time }}</h5>
        </van-step>
      </van-steps>
    </div>
  </template>
  <template v-else>
    <van-empty description="未找到信息" />
  </template>
</template>

<script>
import { toRefs, reactive, onMounted, inject } from 'vue'
import { Toast } from 'vant'

export default {
  setup () {
    const post = inject('post')
    const useRoute = inject('useRoute')
    const state = reactive({
      orderId: useRoute.query.orderId,
      info: {}
    })
    const onTel = (tel) => {
      window.location.href = 'tel://' + tel
    }
    const init = () => {
      post('/order.getOrder', {
        orderId: state.orderId
      }).then(res => {
        if (res.code === 0) {
          post('/util.getLogistics', {
            trackingNumber: res.data.trackingNumber
          }).then(res => {
            if (res.data.status === '0') {
              state.info = res.data
            } else {
              Toast(res.data.msg)
            }
          })
        } else {
          Toast(res.msg)
        }
      })
    }
    onMounted(() => {
      init()
    })
    return {
      ...toRefs(state),
      onTel
    }
  }
}
</script>

<style scoped>
</style>
